<template>
  <b-modal id="modal-xl" centered size="lg" title="查看微信记录" hide-footer>
    <b-form>
      <div>
        <img src="../../assets/images/elements/pyq.png"></img>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BForm, BModal, VBModal,
} from 'bootstrap-vue'

import chat from '../apps/chat/Chat'


export default {
  components: {
    BFormGroup,
    BModal,
    BForm,
    VBModal,
    chat,
  },
  mounted() {},
}
</script>


